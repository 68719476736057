// Import GSAP and Lenis (assuming they're already included in your project)
import { gsap } from 'gsap'

export default function contactModal(lenis) {
  // Pass Lenis instance to this function
  const $ = window.jQuery

  $(document).ready(function () {
    // Function to disable scroll by stopping Lenis
    function disableScroll() {
      lenis.stop() // Stop Lenis scroll
      gsap.to('body', { overflow: 'hidden', duration: 0 }) // Disable body scroll
    }

    // Function to enable scroll by starting Lenis again
    function enableScroll() {
      lenis.start() // Start Lenis scroll
      gsap.to('body', { overflow: 'auto', duration: 0 }) // Enable body scroll
    }

    // Function to open modal
    function openModal(modal) {
      // Use GSAP to animate opacity and visibility
      gsap.to(modal, {
        visibility: 'visible',
        opacity: 1,
        duration: 0.5, // Adjust duration for a smooth fade-in effect
        ease: 'power2.out', // Use easing for smoother effect
      })
      disableScroll() // Disable scroll when modal opens
    }

    // Function to close modal
    function closeModal(modal) {
      // Use GSAP to animate opacity and visibility
      gsap.to(modal, {
        opacity: 0,
        duration: 0.5, // Adjust duration for a smooth fade-out effect
        ease: 'power2.in', // Use easing for smoother effect
        onComplete: function () {
          gsap.set(modal, { visibility: 'hidden' }) // After fade-out, hide the modal
        },
      })
      enableScroll() // Enable scroll when modal closes
    }

    // Event listener for opening modal
    $('[purpose="contact-modal-open"]').on('click', function () {
      const modal = $('.fs_modal-2_component') // Assuming the modal has the ID "modal"
      openModal(modal)
    })

    // Event listener for closing modal
    $('[purpose="close-modal"]').on('click', function () {
      const modal = $('.fs_modal-2_component') // Assuming the modal has the ID "modal"
      closeModal(modal)
    })
  })
}
