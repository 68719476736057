import Lenis from '@studio-freight/lenis'
import flatpickr from 'flatpickr'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import 'flatpickr/dist/flatpickr.min.css' // Import the CSS for styling

import contactModal from './features/contactmodal.js'
import runLoaderAnimation from './features/loader'
document.addEventListener('DOMContentLoaded', () => {
  runLoaderAnimation()
})
// Re-run the loader animation on window resize
window.addEventListener('resize', function () {
  runLoaderAnimation()
})
import './styles/style.css'

document
  .getElementById('initialize-datepicker')
  .addEventListener('click', function () {
    // Initialize Flatpickr when the modal is opened
    flatpickr('#datePickerInput', {
      dateFormat: 'd-m-y', // Customize as needed
      minDate: 'today', // Optional, set minimum date to today
    })
  })

const lenis = new Lenis()
// Pass the Lenis instance to your contact modal script
contactModal(lenis)

lenis.on('scroll', ScrollTrigger.update)

gsap.ticker.add((time) => {
  lenis.raf(time * 1000)
})

gsap.ticker.lagSmoothing(0)

gsap.registerPlugin(ScrollTrigger)

window.addEventListener('load', function () {
  try {
    const portfolioComponents = document.querySelectorAll('[hover-me]')

    portfolioComponents.forEach((portfolioComponent) => {
      try {
        const hoverMessage =
          portfolioComponent.querySelector('[hover-me-unhide]')
        const hoverImage = portfolioComponent.querySelector('[hover-me-image]')

        let Tl = gsap.timeline({ paused: true })

        if (hoverMessage) {
          Tl.to(hoverMessage, { opacity: 1, duration: 0.3 })
        }

        if (hoverImage) {
          Tl.from(
            hoverImage,
            { scale: 1.01, duration: 0.5, ease: 'power1.out' },
            0
          ) // Add scale animation for hover image
        }

        portfolioComponent.addEventListener('mouseover', function () {
          Tl.play()
        })

        portfolioComponent.addEventListener('mouseout', function () {
          Tl.reverse()
        })
      } catch (componentError) {
        // Do nothing if an error occurs
      }
    })
  } catch (error) {
    // Do nothing if an error occurs
  }
})
