import { gsap } from 'gsap'

// Define the loader animation function
export default function runLoaderAnimation() {
  const $ = window.jQuery
  // Initialize the counter and loader duration variables
  let counter = { value: 0 }
  let loaderDuration = 1

  // Check if this is not the first time the user has visited this page
  if (sessionStorage.getItem('visited') !== null) {
    // If so, set the loader duration to 0.5 seconds and the counter value to 75
    loaderDuration = 0.5
    counter = { value: 75 }
  }

  // Set the visited item in the session storage to "true"
  sessionStorage.setItem('visited', 'true')

  // Define a function to update the loader text
  function updateLoaderText() {
    let progress = Math.round(counter.value)
    $('.loader4_number').text(progress)
  }

  // Define a function to end the loader animation
  function endLoaderAnimation() {
    $('.loader4_ix-trigger').click()
  }

  // Create a timeline animation using the GreenSock Animation Platform (GSAP)
  let tl = gsap.timeline({
    onComplete: endLoaderAnimation,
  })

  // Animate the counter value from 0 to 100 with the defined custom ease animation
  tl.to(counter, {
    value: 100,
    onUpdate: updateLoaderText,
    duration: loaderDuration,
    ease: 'easeInOut',
  })

  // Animate the width of the loader progress bar to 100% with the defined custom ease animation
  tl.to(
    '.loader4_progress-bar',
    {
      width: '100%',
      duration: loaderDuration,
      ease: 'easeInOut',
    },
    0
  )
  gsap.set('.loader4_component', { display: 'flex' })
  gsap.set('.loader3_image', { opacity: 0.9 })
  gsap.set('.loader4_progress-wrapper', { width: '40%', opacity: 1 })
  gsap.set('.loader4_progress-content', { height: 'auto' })
  let loaderTimeline = gsap.timeline({ paused: true })
  loaderTimeline
    .to('.loader4_progress-content', {
      duration: 0.4,
      delay: 0.4,
      height: 0,
      opacity: 0,
      ease: 'power4.out',
    })
    .to('.loader4_progress-wrapper', {
      duration: 0.4,
      width: '100svw',
      ease: 'power4.out',
    })
    .to('.loader4_progress-wrapper', {
      duration: 0.4,
      opacity: 0,
      ease: 'power4.out',
    })
    .to('.loader4_background-top', {
      duration: 0.4,

      y: '-100%',
      ease: 'power2.inOut',
    })
    .to(
      '.loader4_background-bottom',
      {
        duration: 0.4,
        y: '100%',
        ease: 'power2.inOut',
      },
      '-=0.4'
    )
    .to('.loader4_progress-wrapper', {
      duration: 0.4,
      opacity: 0,
      ease: 'power4.out',
    })
    .to('.loader4_component', { display: 'none', visibility: 'hidden' })

  window.addEventListener('load', () => {
    loaderTimeline.play()
  })
}
